import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { PrivyProvider } from '@privy-io/react-auth'
import { Provider } from 'react-redux'
import store from './store'

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <PrivyProvider
        appId={process.env.REACT_APP_PRIVY_APP_ID}
        clientId="client-WY5ckuLyGqLBNrmUpQ5KqsmSP6CtFn6LdhEw7u69ccn6L"
        config={{
          appearance: {
            accentColor: '#26a269',
            theme: '#222224',
            showWalletLoginFirst: false,
            logo: 'https://cdn.prod.website-files.com/6676e3fa22cb2a860ec4ef00/667c83e28550c92899109f1f_DeEEP_Logo-03-p-500.png',
            walletChainType: 'ethereum-only',
            walletList: ['detected_ethereum_wallets'],
          },
          loginMethods: ['email', 'google', 'discord', 'wallet'],
        }}
        onSuccess={(user) => console.log(`User ${user.id} logged in!`)}
      >
        <App />
      </PrivyProvider>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
