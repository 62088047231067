import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { usePrivy } from '@privy-io/react-auth'

const PrivateRoute = ({ element }) => {
  const { ready, authenticated } = usePrivy()

  if (!ready) {
    return <div>Loading...</div> // Optionally replace with a loading spinner
  }

  if (!authenticated) {
    return <Navigate to="/login" replace />
  }

  return element // Render the provided React element directly
}

PrivateRoute.propTypes = {
  element: PropTypes.element.isRequired, // Expect a valid React element
}

export default PrivateRoute
